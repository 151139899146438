import React from "react";

import { Card, Container, Icon, Image } from "semantic-ui-react";
import { Observation } from "../../typings";
import { ObservationResult } from "./ObservationResult";
import nemsLogo from "../assets/nemslogo2020.png";
import LanguagePicker from "./LanguagePicker";
import i18n from "../utils/i18n";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export const PatientObservationMessage: React.FunctionComponent<{
  patientName: string;
  medicalRecordNumber: string;
  dateOfBirth: DateTime | null | undefined;
  observations: Observation[];
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Image centered={true} src={nemsLogo} size="medium"></Image>
      <LanguagePicker languageDidChange={(lng) => i18n.changeLanguage(lng)} />
      <br />
      <br />
      <Card fluid raised={false} className="demographic">
        <Card.Content textAlign="center">
          <h2>{t("page.labResults.title")}</h2>
          <Icon name="lab"></Icon>{" "}
        </Card.Content>
        <Card.Content>
          <table>
            <tr>
              <td>
                <strong>{t("label.patient")}</strong>
                {props.patientName}
              </td>
              <td>
                <strong>{t("label.medicalRecordNumber")}</strong>
                {props.medicalRecordNumber}
              </td>
              <td>
                <strong>{t("label.dateOfBirth")}</strong>
                {props.dateOfBirth?.toLocaleString(DateTime.DATE_SHORT)}
              </td>
            </tr>
          </table>
        </Card.Content>
      </Card>

      <br />
      {props.observations.map((observation) => (
        <ObservationResult
          observationClass={observation.observationClass}
          observationName={observation.observationName}
          date={observation.date}
          provider={observation.provider}
          result={observation.result}
        />
      ))}
    </Container>
  );
};
