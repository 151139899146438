import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

import { Card } from "semantic-ui-react";
import { BirthForm } from "./BirthForm";
import qs from "qs";
import axios from "axios";
import { IPatientObservationMessage } from "../../typings";
import { useTranslation } from "react-i18next";

export const MessageAuth: React.FunctionComponent<{
  className?: string | undefined;

  setMessage: (observationMessage: IPatientObservationMessage) => void;
  hadError: (errorMessage: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const [messageId, setMessageId] = useState("");
  const [secret, setSecret] = useState("");
  const [observationMessage, setObservationMessage] =
    useState<IPatientObservationMessage | null>(null);

  useEffect(() => {
    const pathMessageId = window.location.pathname.replace(/\/([^/]*)/, "$1");
    const queryStrings = qs.parse(window.location.search);
    console.log(queryStrings);
    const querySecret = queryStrings["secret"] ?? queryStrings["?secret"];
    if (typeof querySecret !== "string") {
      setSecret("");
      return;
    }

    setMessageId(pathMessageId);
    setSecret(querySecret);

    return;
  }, []);

  useEffect(() => {
    if (observationMessage !== null) {
      props.setMessage(observationMessage);
    }
  }, [observationMessage, props]);

  const retrieveMessage = async (
    messageId: string,
    secret: string,
    dateOfBirth: DateTime
  ) => {
    try {
      const url = `https://api.nems.org/secure-messages/${messageId}?secret=${secret}&dateOfBirth=${dateOfBirth.toISODate()}`;
      let result = await axios.get<IPatientObservationMessage>(url);
      setObservationMessage(result.data);
      return;
    } catch (err) {
      props.hadError("Please retry your birth date again");
      return;
    }
  };

  return (
    <Card className={props.className} centered raised fluid>
      <Card.Content>
        <Card.Header>
          <span
            style={{
              textAlign: "center",
              width: "100%",
              display: "block",
            }}
          >
            {t("landing.dateOfBirth")}
          </span>
          <br />
        </Card.Header>
        <Card.Description>
          <BirthForm
            getDateOfBirth={async (dateOfBirth) => {
              await retrieveMessage(messageId, secret, dateOfBirth);
            }}
          />
        </Card.Description>
      </Card.Content>
    </Card>
  );
};
