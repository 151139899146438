/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Observation } from "../typings";
import { MessageAuth } from "./components/MessageAuth";

import { Message, Image, Container } from "semantic-ui-react";
import { PatientObservationMessage } from "./components/PatientObservationMessage";
import nemsLogo from "./assets/nemslogo2020.png";
import { useTranslation } from "react-i18next";
import LanguagePicker from "./components/LanguagePicker";
import { DateTime } from "luxon";

const App: React.FunctionComponent = () => {
  const [observations, setObservations] = useState<Observation[] | null>();
  const [patientName, setPatientName] = useState<string>("");
  const [patientDateOfBirth, setPatientDateOfBirth] =
    useState<DateTime | null>();
  const [medicalRecordNumber, setMedicalRecordNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { t, i18n } = useTranslation();

  const handleError = (message: string) => {
    setErrorMessage(message);
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 1000 * 10);
  }, [errorMessage]);

  const Login = (
    <div className="App">
      <Container>
        <Image centered={true} src={nemsLogo} size="medium"></Image>
      </Container>
      <br />
      <LanguagePicker languageDidChange={(lng) => i18n.changeLanguage(lng)} />
      <MessageAuth
        className="authForm"
        hadError={handleError}
        setMessage={(ob) => {
          setObservations(
            ob.observations.map((ob) => {
              return {
                observationClass: ob.observationClass,
                date: DateTime.fromISO(ob.date),
                provider: ob.provider,
                result: ob.result,
                observationName: ob.name,
                messageTemplate: ob.templateMessage,
              };
            })
          );
          setPatientName(`${ob.patient.firstName} ${ob.patient.lastName}`);
          setMedicalRecordNumber(ob.patient.medicalRecordNumber);
          setPatientDateOfBirth(DateTime.fromISO(ob.patient.dateOfBirth));
        }}
      />
      <Message>{t("landing.disclaimer")}</Message>
      <br />
    </div>
  );

  if (errorMessage) {
    return (
      <div>
        <Message error={true} content="Please try again" />
        {Login}
      </div>
    );
  }

  if (observations != null) {
    return (
      <div className="App">
        <PatientObservationMessage
          observations={observations}
          patientName={patientName}
          medicalRecordNumber={medicalRecordNumber}
          dateOfBirth={patientDateOfBirth}
        />
      </div>
    );
  }

  return Login;
};

export default App;
