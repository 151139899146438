import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Icon } from "semantic-ui-react";
import { Observation } from "../../typings";
import { ObservationMessageDetails } from "./ObservationMessageNotes";

export const ObservationResult: React.FunctionComponent<Observation> = (
  props
) => {
  const { t } = useTranslation();

  return (
    <Card fluid>
      <Card.Content header={props.observationName} textAlign="center" />
      <Card.Content textAlign="left">
        <strong>{t("label.observation.result")}</strong>
        {"  "}
        {t(`result.value.${props.result}`)}
      </Card.Content>
      <ObservationMessageDetails
        observationClass={props.observationClass}
        observationResult={
          props.result.match(/positive/i)
            ? t("result.positive")
            : t(`result.${props.result}`)
        }
      />
      <Card.Content meta>
        <strong>{t("label.observation.provider")}</strong>
        {"  "}
        {props.provider}
        <Icon name="user doctor" corner="bottom right" />
      </Card.Content>
      <Card.Content extra>
        <strong>{t("label.observation.date")}</strong>
        {"  "}
        {props.date.toLocaleString(DateTime.DATE_MED)}
        <Icon name="calendar" />
      </Card.Content>
    </Card>
  );
};
