import React, { useState } from "react";
import { Menu } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const LanguagePicker: React.FunctionComponent<{
  languageDidChange: (lng: string) => void;
}> = (props) => {
  const { i18n } = useTranslation();

  const languageOptions = [
    { key: 1, text: "中文", code: "zh-Hant" },
    { key: 3, text: "English", code: "en" },
    { key: 4, text: "Español", code: "es" },
    { key: 5, text: "Tiếng Việt", code: "vi" },
  ];

  const [selectedLanguage, setLanguage] = useState<string>(i18n.language);

  const selectLanguage = (langCode: string) => {
    props.languageDidChange(langCode);
    setLanguage(langCode);
    return;
  };

  return (
    <Menu widths={4} size="mini">
      {languageOptions.map((lang) => {
        console.log(lang.text);
        return (
          <Menu.Item
            key={lang.key}
            name={lang.code}
            content={lang.text}
            active={selectedLanguage === lang.code}
            onClick={() => selectLanguage(lang.code)}
          ></Menu.Item>
        );
      })}
    </Menu>
  );
};

export default LanguagePicker;
