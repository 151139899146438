/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTime } from "luxon";
import React, { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Message, StrictFormInputProps } from "semantic-ui-react";

// import { DateTime } from "luxon";

export const BirthForm = (
  props: PropsWithChildren<{
    getDateOfBirth: (dateOfBirth: DateTime) => void;
  }>
) => {
  const now = DateTime.local();
  const { t } = useTranslation();

  let [month, setMonth] = useState<string>("");
  let [loadingMessage, setLoadingMessage] = useState(false);
  let [isMonthInvalid, setIsMonthInvalid] = useState<
    null | StrictFormInputProps["error"]
  >(null);

  let [day, setDate] = useState<string>("");
  let [isDayValid, setIsDayInvalid] = useState<
    null | StrictFormInputProps["error"]
  >(null);

  let [year, setYear] = useState<string>("");
  let [_isYearInvalid, setIsYearInvalid] = useState<
    null | StrictFormInputProps["error"]
  >(null);

  let [_dateOfBirth, setDateOfBirth] = useState<DateTime | null>(null);

  let [hasBadDateOfBirth, setHasBadDateOfBirth] = useState<boolean>(false);

  const submitBirthDate = () => {
    month = printDigitWithLeadingZero(month);
    day = printDigitWithLeadingZero(day);
    const BIRTH_DATE = DateTime.fromISO(`${year}-${month}-${day}`);
    setLoadingMessage(true);
    if (!BIRTH_DATE.isValid) {
      setDateOfBirth(null);
      setHasBadDateOfBirth(true);
      setLoadingMessage(false);
      return null;
    }
    setTimeout(() => {
      setLoadingMessage(false);
    }, 600);
    setDateOfBirth(BIRTH_DATE);
    setHasBadDateOfBirth(false);
    props.getDateOfBirth(BIRTH_DATE);
  };

  const updateMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    // Keep only the first 2 digits, replace everything else
    let value = input.replace(/^(\d{2}).*/, "$1").replace(/[^\d]/g, "");

    if (value === "" || value.match(/^0/)) {
      setIsMonthInvalid(null);
      setMonth(value);
      return;
    }

    // Acceptable values are 1 - 12
    let monthNumber = parseInt(value, 10);
    if (monthNumber < 1 || monthNumber > 12) {
      setIsMonthInvalid({
        content: t("birthdayForm.errors.invalidMonth"),
        pointing: "above",
      });
      setMonth(value);
      return;
    }
    setIsMonthInvalid(null);
    setMonth(value);
  };

  const updateDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    // Keep only the first 2 digits, replace everything else
    let value = input.replace(/^(\d{2}).*/, "$1").replace(/[^\d]/g, "");

    if (value === "" || value.match(/^0/)) {
      setIsDayInvalid(null);
      setDate(value);
      return;
    }

    // Acceptable values are 1 - 31
    let dayNumber = parseInt(value, 10);
    if (dayNumber < 1 || dayNumber > 31) {
      setIsDayInvalid({
        content: t("birthdayForm.errors.invalidDay"),
        pointing: "above",
      });
      setDate(value);
      return;
    }
    setIsDayInvalid(null);
    setDate(value);
  };

  const updateYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    // Keep only the first 2 digits, replace everything else
    let value = input
      .replace(/^(\d{5}).*/, "$1")
      .replace(/[^\d]/g, "")
      .replace(/^0/g, "");

    setIsYearInvalid(null);
    setYear(value);
  };

  const printDigitWithLeadingZero = (num: string | number): string => {
    let n: number;
    if (typeof num === "number") {
      n = num;
    } else {
      n = parseInt(num, 10);
    }

    if (n < 10) {
      return "0" + n.toString();
    }
    return n.toString();
  };

  return (
    <div>
      <Message
        content={t("birthdayForm.errors.invalidDate")}
        error={true}
        hidden={!hasBadDateOfBirth}
      />
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label={t("birthdayForm.month")}
            type="text"
            placeholder={now.month.toString(10)}
            error={isMonthInvalid}
            width={4}
            required={true}
            value={month}
            onChange={updateMonth}
          />
          <Form.Input
            fluid
            label={t("birthdayForm.day")}
            type="text"
            placeholder={now.day.toString(10)}
            width={4}
            required={true}
            error={isDayValid}
            value={day}
            onChange={updateDay}
          />
          <Form.Input
            fluid
            label={t("birthdayForm.year")}
            type="text"
            placeholder={now.year.toString(10)}
            width={6}
            required={true}
            value={year}
            onChange={updateYear}
          />
        </Form.Group>

        <Form.Button
          floated="right"
          width={16}
          fluid={true}
          content={t("birthdayForm.continueButton")}
          color="red"
          loading={loadingMessage}
          disabled={loadingMessage}
          onClick={submitBirthDate}
        />
      </Form>
    </div>
  );
};
