import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "semantic-ui-react";

export const ObservationMessageDetails: React.FunctionComponent<{
  observationClass: string;
  observationResult: "positive" | "negative";
}> = ({ observationClass, observationResult }) => {
  const { t } = useTranslation();

  const message = t(`message.${observationClass}.${observationResult}`);

  if (!message) {
    return null;
  }

  return (
    <Card.Content textAlign="left">
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </Card.Content>
  );
};
