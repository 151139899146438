import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "semantic-ui-css/semantic.min.css";

import "./App.css";
import "./utils/i18n";

ReactDOM.render(
  <Suspense fallback="...loading">
    <App />
  </Suspense>,
  document.getElementById("root")
);
